@media screen and (min-width: 1200px){
	.container{
		max-width: 1200px;
	}
}
@media screen and (max-width: 1200px){
	.site-header .login,
	.site-header .search-icon,
	.site-header .main-menu{
		margin-left: 30px;
	}
	.site-header .main-menu ul li{
		margin-left: 20px;
	}
	.site-main .sideshow .sideshow-content h1{
		font-size: 38px;
	}
	.section .section-item .section-box .process .process-info div span{
		margin-right: 55px;
	}
	.staff-picks.section .staff-picks-item .staff-picks-item-content .process-info div span{
		margin-right: 60px !important;
	}
	.top-site{
		height: 600px;
	}
	.top-site .container{
		top: 140px;
	}
	.statics-content .statics-item{
		min-height: 210px;
	}
	.campaign-detail .button .btn-secondary,
	.campaign-detail .button .btn-primary{
		margin-right: 0;
	}
	.product-table tr td,
	.product-table tr th{
		padding: 15px;
	}
	.coupon-form form input{
		width: 65%;
	}
	.project-love-item a.project-love-image,
	.project-love-item a.project-love-image img{
		width: 100%;
	}
	.pls-col .project-love-item a.project-love-image,
	.pls-col .project-love-item a.project-love-image img{
		width: 370px;
	}
	.project-love-item a.project-love-image{
		margin-bottom: 20px;
	}
	.project-love-item .project-love-item-content{
		padding: 0;
	}
	.project-love .col-lg-4,
	.project-love .col-lg-8{
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.popular-project{
		margin-bottom: 40px;
	}
	.popular-project{
		height: auto;
		padding: 20px 0 30px;
	}
}
@media screen and (max-width: 992px){
	.info .far, .info .fas {
		font-size: 2em;
	  }
	.title {
		font-size: 30px;
	}
	.subtitle {
		font-size: 24px;
	}
	.description{
		font-size: 16px;
	}
	.how-it-work .promise {
    margin-top: 10px;
	}
	.how-it-work .promise-title {
    font-size: 16px;
	}
	.how-it-work .promise-text {
		font-size: 20px;
	}	
	.payment-img{
		float: none;
		margin-bottom: 30px;
	}
	.footer-top .footer-top-right{
		float: none;
	}
	.footer-top-right .dropdow:nth-child(1){
		margin: 0;
		width: 48%;
		float: left;
	}
	.footer-top .footer-top-right:after{
		content: '';
		display: block;
		clear: both;
	}
	.footer-top-right .dropdow:nth-child(2){
		margin: 0;
		width: 48%;
		float: right;
	}
	.pls-col .project-love-item a.project-love-image,
	.pls-col .project-love-item a.project-love-image img{
		width: 100%;
	}
	.pls-col .project-love-item .project-love-item-content{
		margin: 30px 0 0 0;
	}
	.pls-col .project-love-item a.project-love-image{
		float: none;
	}
	.bx-wrapper{
		margin-bottom: 0;
	}
	.site-header{
		height: auto;
		z-index: 9;
		position: relative;
		background-color: #fff;
	}
	.c-hamburger{
		display: block;
	}
	.main-menu ul{
		position: fixed;
		z-index: 999999;
		background-color: #fff;
		top: 80px;
		left: -240px;
		margin-left: 0;
		border-top: 1px solid #FB5607;
		width: 240px;
		transition: all 0.4s;
	}
	.home-top .main-menu ul{
		top: 125px;
	}
	.main-menu.open ul{
		left: 0;
	}
	body.menu-open:before{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		background: rgba(0, 0, 0, 0.6);
		content: "";
		z-index: 9;
		transition: all 0.3s ease-in-out 0s;
		-moz-transition: all 0.3s ease-in-out 0s;
		-o-transition: all 0.3s ease-in-out 0s;
		-webkit-transition: all 0.3s ease-in-out 0s;
		-ms-transition: all 0.3s ease-in-out 0s;
	}
	.site-header .main-menu ul li{
		display: block;
		margin-left: 0;
		line-height: normal;
		border-bottom: 1px dashed #ccc;
	}
	.site-header .main-menu ul li:last-child{
		border-bottom: 0;
	}
	.site-header .main-menu ul li a{
		padding: 13px 15px;
	}
	.site-header .login,
	.site-header .search-icon{
		margin-left: 15px;
	}
	.site-main .sideshow .sideshow-content{
		max-width: 100%;
		width: auto;
		padding-right: 15px;
	}
	.site-main .sideshow{
		height: 400px;
	}
	.site-main .section,
	.explore,
	.project-love{
		padding: 60px 0 0;
	}
	.staff-picks{
		padding: 30px 0 0;
	}
	.campaign .campaign-item{
		margin-bottom: 30px;
	}
	.staff-picks .staff-picks-item a.staff-picks-image{
		float: none;
	}
	.staff-picks .staff-picks-item a.staff-picks-image img{
		width: 100%;
	}
	.staff-picks .staff-picks-item .staff-picks-item-content{
		padding: 0;
		margin-top: 15px;
	}
	.staff-picks-item-content .staff-picks-description{
		height: auto;
		max-height: initial;
		overflow: initial;
		margin-bottom: 10px;
	}
	.staff-picks.section .staff-picks-item .staff-picks-item-content .process-info div span{
		margin-right: 150px !important; 
	}
	.main-menu ul li i{
		float: right;
		line-height: 18px;
	}
	.main-menu ul li > .sub-menu{
		position: static;
		width: auto;
		opacity: 1;
		visibility: visible;
		border-top: 1px dashed #ccc;
		display: none;
		transition: all 0.4s;
	}
	.main-menu ul li > .sub-menu li{
		padding-left: 20px;
		border-top: 0;
	}
	.partners{
		padding: 30px 0;
	}
	.footer-menu-item.newsletter,
	.campaign-history{
		margin-top: 30px;
	}
	.site-footer .footer-menu{
		padding: 30px 0 20px;
	}
	.video iframe{
		max-width: 100%;
	}
	.statics-content .statics-item{
		min-height: 161px;
		margin-bottom: 30px;
	}
	.team-item,
	.comment-respond{
		margin-bottom: 30px;
	}
	.blog-grid.full-width .post{
		width: 50%;
	}
	.page-title,
	.latest{
		margin-bottom: 60px;
	}
	.site-main{
		padding-bottom: 60px;
	}
	.main-content{
		margin-bottom: 30px;
	}
	.campaign-detail .campaign-image,
	.campaign-detail .campaign-image img,
	.campaign-detail .campaign-box{
		width: 100%;
		float: none;
	}
	.campaign-detail .campaign-box{
		padding-left: 0;
		margin-top: 10px;
	}
	.campaign-detail .button .btn-secondary{
		margin-left: 8px;
	}
	.campaign-detail .campaign-box .campaign-description{
		max-height: 100% !important;
	}
	.campaign-tabs .campaign-content{
		margin-bottom: 30px;
	}
	.calculate-shipping{
		margin-bottom: 30px;
	}
	.coupon-form form input{
		width: 78%;
	}
	.coupon-form form{
		margin-bottom: 15px;
	}
	.coupon-form .button{
		float: none;
	}
	.order-notes{
		margin-bottom: 30px;
	}
	.coming-soon .site-header{
		background-color: initial;
	}
	.contact-content .entry-content,
	.form-contact,
	.contact-info{
		height: auto;
	}
	.form-contact,
	.blognews{
		margin-bottom: 30px;
	}
	.maps #map{
		margin-top: 30px;
	}
	.how-it-work{
		padding: 60px 0;
		margin-top: 60px;
	}
	.item-work .item-content h3{
		margin-bottom: 5px;
	}
	.item-work:after{
		content: '';
		clear: both;
		display: block;
	}
	.item-work,
	.blognews-content .post{
		margin-bottom: 30px;
	}
	.campaign-big-item a.campaign-big-image {
    float: none;
  }
	.campaign-big-item a.campaign-big-image img{
		width: 100%;
	}
	.campaign-big-item .campaign-big-box{
		padding: 20px;
		margin: 0;
	}
	.campaign-big-box .process .process-info span{
		margin-right: 100px !important;
	}
	.process-model li{
		padding: 0 10px;
	}
	.process-model li span{
		width: auto;
		height: 80px;
		line-height: 68px;
		border: 6px solid #08aded;
	}
	.process-model li span img{
		width: 24px;
	}
	.process-model li::after{
		top: 40px;
	}
	.field-checkbox label{
		width: 96%;
	}
}
@media screen and (max-width: 767px){
	.site-main .sideshow .sideshow-content h1{
		font-size: 32px;
	}
	.site-main .sideshow .sideshow-content .sideshow-description{
		font-size: 18px;
	}
	.campaign-history .tabs-controls li a{
		font-size: 14px;
	}
	.campaign-history .tabs-controls li{
		margin-right: 30px;
	}
	.product-table{
		overflow-x: auto; 
	}
	.product-table table{
		width: 767px;
	}
	.coupon-form form input{
		width: 100%;
		padding: 0 145px 0 15px;
	}
	.coming-soon .site-header{
		padding: 40px 0;
	}
	.coming-soon .site-footer .footer-copyright{
		padding: 40px;
	}
	.coming-soon .site-main .time{
		margin-top: 60px;
	}
	.coming-soon .site-main{
		padding: 0;
	}
	.coming-soon .site-main .time .time-item p{
		font-size: 60px;
	}
	.coming-soon .site-main .time .time-item span{
		margin-top: 10px;
	}
	.product{
		width: 33.33%;
	}
	.newsletter-popup{
		width: auto;
		margin: 0 30px;
	}
	.top-header{
		display: none;
	}
	.description.left-description{
		width: 65%;
	}
	.campaigns-action .sort,
	.campaigns-action .filter{
		float: none;
	}
	.campaigns-action .sort{
		margin-bottom: 15px;
	}
	.process-model li span{
		height: 52px;
		border-width: 4px;
		line-height: 44px;
	}
	.process-model li::after{
		top: 26px;
	}
	.process-model li span img{
		width: 18px;
	}
	.process-model li a p{
		font-size: 12px;
	}
	.site-footer .footer-top{
		padding-top: 0;
	}
	.footer-top .copyright{
		float: none;
	}
	.footer-menu .footer-menu-item li{
		margin-bottom: 20px;
	}
	.campaign-content .col-6{
		width: 100%;
		flex: initial;
		max-width: 100%;
	}
	.home-top .main-menu ul{
		top: 80px;
	}
}
@media screen and (max-width: 576px){
	.site-header .login a{
		font-size: 0;
		padding: 0 14px;
	}
	.site-header .login-button a:before{
		content: "\f007";
		font-size: 16px;
		font-family: 'Font Awesome\ 5 Free';
	}
	.site-header .login-button i {
		display: none;
	}
	.site-header .login-button.donate a:before{
		content: "\f3d1";
	}
	.site-header .login,
	.site-header .search-icon{
		margin-left: 10px;
	}
	.site-main .sideshow{
		height: 450px;
	}
	.process .process-info div{
		margin-bottom: 20px;
	}
	.section .section-item .section-box .process .process-info div span{
		margin-right: 70px;
	}
	.explore .col-6,
	.team-content .col-6,
	.statics-content .col-6{
		width: 100%;
		flex: initial;
		max-width: 100%;
	}
	.project-love .process .process-info span,
	.project-love .campaign-big-box .process .process-info span{
		font-size: 14px;
		margin-right: 0 !important;
	}
	.project-love .process .process-info div{
		width: 50%;
	}
	.process .process-info{
		margin-bottom: 20px;
	}
	.campaign-item .process .process-info div,
	.campaign-item .process .process-info{
		margin-bottom: 0;
	}
	.staff-picks-content .owl-controls{
		display: none;
	}
	.staff-picks-author .author-address,
	.staff-picks-author .author-profile{
		float: none;
		display: block;
	}
	.staff-picks-author .author-address{
		text-align: left;
		margin-top: 10px;
	}
	.latest{
		margin-bottom: 30px;
	}
	.latest .latest-button{
		margin-top: 0;
	}
	.footer-menu .col-4{
		width: 50%;
		margin-bottom: 30px;
	}
	.footer-menu .col-12{
		width: 50%;
	}
	.footer-menu-item.newsletter{
		margin: 0;
	}
	.site-footer .footer-menu,
	.project-love{
		padding: 30px 0 0 0;
	}
	.site-footer .footer-copyright{
		padding: 20px 0;
	}
	.page-title{
		margin-bottom: 60px;
		min-height: 180px;
	}
	.site-main{
		padding-bottom: 60px;
	}
	.top-site h1{
		font-size: 24px;
		max-width: 100%;
	}
	.top-site{
		height: 500px;
	}
	.top-site .container{
		top: 100px;
	}
	.video iframe{
		height: 300px;
	}
	.video .video-wrapper{
		margin-top: -150px;
	}
	.statics-content .statics-item{
		min-height: auto;
	}
	.page-title h1{
		font-size: 24px;
	}
	.blog-grid.full-width .post,
	.blog-grid .post{
		width: 100%;
	}
	.page-navigation{
		margin-top: 0;
	}
	.page-title,
	.story{
		margin-bottom: 30px;
	}
	.site-main{
		padding-bottom: 30px;
	}
	.campaign-detail .campaign-price{
		margin-bottom: 15px;
	}
	.campaign-detail .button .btn-secondary{
		margin-left: 0;
	}
	.campaign-detail .button .btn-primary{
		margin-right: 8px;
	}
	.campaign-history{
		margin-top: 0;
	}
	.campaign-history .tabs-controls li{
		margin-right: 20px;
	}
	.campaign-history .tabs-controls li a{
		font-size: 12px;
	}
	.cart .main-content .col-6{
		width: 100%;
		flex: initial;
		max-width: 100%;
	}
	.coupon-form .button .update-cart{
		margin-right: 8px;
	}
	.coming-soon .site-main .desc{
		width: 100%;
	}
	.time .col-3{
		width: 50%;
		margin-bottom: 30px;
	}
	.coming-soon .site-main .button{
		margin-top: 20px;
	}
	.coming-soon .site-footer .footer-copyright p{
		float: none;
		margin: 0 0 15px 0;
		text-align: center;
	}
	.coming-soon .site-footer .footer-copyright .follow{
		float: none;
		text-align: center;
	}
	.campaigns .campaign-content .col-6{
		width: 100%;
		flex: initial;
		max-width: 100%;
	}
	.product{
		width: 50%;
	}
	.newsletter-popup{
		height: 380px;
	}
	.form-content form input{
		position: relative;
		width: 100%;
	}
	.form-content form button{
		position: relative;
		width: 100%;
		margin-top: 20px;
	}
	.coming-soon .site-main .time li{
		width: 50%;
		margin-bottom: 30px;
	}
	.project-love .col-9,
	.project-love .col-3{
		width: 100%;
		flex: initial;
		max-width: 100%;
	}
	.project-love .tab-menu{
		padding-left: 100px;
	}
	.project-love .tab-menu{
		padding: 0;
		margin-top: 20px;
	}
	ul.menu-category{
		border-left: 0;
		border-top: 1px solid #ededed;
		letter-spacing: -0.3em;
	}
	ul.menu-category li{
		display: inline-block;
		width: 33.33%;
		letter-spacing: normal;
	}
	ul.menu-category li.active::before,
	ul.menu-category li.active::after{
		display: none;
	}
	ul.menu-category li{
		padding: 0 5px;
	}
	ul.menu-category li.active a{
		color: #FB5607;
	}
	.project-love .tab-menu a.all-project{
		left: 5px;
		top: -30px;
	}
	.how-it-work{
		padding: 30px 0;
		margin-top: 0;
	}
	.process-model li span{
		width: 50px;
		height: 50px;
		line-height: 40px;
	}
}
@media screen and (max-width: 450px){
	.cookies {
		flex-flow: column;
	}
	.cookies button {
		margin-top: 10px;
	}
	.footer-menu .col-4,
	.footer-menu .col-12{
		width: 100%;
		flex: initial;
		max-width: 100%;
	}
	.site-footer .footer-copyright p,
	.site-footer .footer-copyright a.back-top{
		font-size: 12px;
	}
	.btn-secondary{
		margin-right: 10px;
	}
	.entry-content ul.post-meta li{
		margin-bottom: 5px;
	}
	.widget li,.widget li a{
		font-size: 14px;
	}
	.widget.widget-recent{
		margin-bottom: 0;
	}
	.widget.widget-search{
		padding: 0;
	}
	.widget{
		padding: 15px;
	}
	.widget-title{
		margin-bottom: 15px;
	}
	.campaign-history .tabs-controls{
		border: 0;
	}
	.campaign-history .tabs-controls li{
		display: block;
		padding-bottom: 5px;
		margin-bottom: 5px;
	}
	.campaign-history .tabs-controls li a{
		font-size: 14px;
	}
	.coupon-form .button button{
		width: 100%;
	}
	.coupon-form .button .update-cart{
		margin-right: 0;
		margin-bottom: 15px;
	}
	form .align-left,
	form .align-right{
		float: none;
		width: 100%;
		padding: 0;
	}
	form .align-left{
		margin-bottom: 30px;
	}
	.different-address label{
		font-size: 14px;
	}
	.maps #map{
		height: 300px;
	}
	.form-login {
		margin-top: 20px;
	}
	.form-login .inline p,
	.form-login .btn-primary{
		float: none;
	}
	.form-login .inline p{
		margin-top: 10px;
	}
	.shop-slider .owl-controls .owl-page{
		height: 80px;
	}
	.reviews .reviews-vote .vote,
	.shop-grid-fillter p{
		float: none;
		margin-bottom: 10px;
	}
	.reviews .reviews-vote .btn-primary{
		float: none;
		display: block;
	}
	.product{
		width: 100%;
	}
	.shop-grid-fillter p,
	.shop-grid-fillter .field-select,
	.form-header img{
		float: none;
	}
	.form-header img{
		display: block;
		margin: 0 auto;
		margin-bottom: 10px;
		width: 60px;
		height: 60px;
	}
	.form-header{
		padding: 20px 30px;
	}
	.form-header .form-text{
		padding: 0;
	}
	ul.menu-category li{
		width: 50%;
	}
	.pls-col .process .process-info div{
		width: 50%;
	}
	.project-love .pls-col .process-info span{
		margin: 0 !important;
	}
	.process-model li{
		width: 20%;
	}
	.process-model{
		position: relative;
		padding-top: 100px;
		margin: 20px auto;
	}
	.process-model li:first-child{
		position: absolute;
		top: 0;
		left: 0;
	}
	.process-model li:last-child{
		position: absolute;
		top: 0;
		right: 0;
	}
	.list-upload .file-upload{
		width: 50%;
	}
	.footer-top-right .dropdow:nth-child(1){
		margin: 0;
		width: 100%;
	}
	.footer-top-right .dropdow:nth-child(2){
		margin: 20px 0 0 0;
		width: 100%;
	}
	.sideshow-content .process .process-info span,
	.sideshow-content .campaign-big-box .process .process-info span{
		margin-right: 0 !important;
	}
	.sideshow-content .process .process-info div{
		width: 50%;
	}
	.sideshow-content .process .process-info{
		margin-bottom: 0;
	}
	.campaign-tabs button{
		margin-right: 25px;
	}

	.page-title{
		min-height: 100px;
		margin-bottom: 20px;
	}	

	.cart-totals .cart-description {
		margin: 10px 0;
	}
	.cart-totals ul li {
		padding: 10px 0;
	}
}
@media screen and (max-width: 360px){
	.site-header .login,
	.site-header .search-icon{
		margin-left: 6px;
	}
	.site-main .sideshow{
		height: 500px;
	}
	.btn-secondary,
	.btn-primary{
		height: 38px;
		line-height: 34px;
		padding: 0 15px;
	}
	.btn-primary{
		line-height: 38px;
	}
	.site-main .section,
	.campaign,
	.explore{
		padding: 30px 0 0;
	}
	.staff-picks{
		padding: 10px 0 0;
	}
	.site-footer .footer-copyright a.back-top{
		font-size: 0;
	}
	.main-404 .btn-secondary,
	.main-404 .btn-primary{
		width: 100%;
		line-height: 42px;
		height: 42px;
	}
	.main-404 .btn-secondary{
		margin: 0 0 15px 0;
		line-height: 38px;
	}
	.top-site{
		height: 400px;
	}
	.top-site .container{
		top: 60px;
	}
	.statics-content .statics-item h3,
	.page-title h1,
	.coming-soon .site-main h2{
		font-size: 18px;
	}
	.title,.coming-soon .site-main h1{
		font-size: 24px;
	}
	.entry-content ul.list,
	.entry-content .quote{
		margin-left: 30px;
	}
	.title.left-title{
		font-size: 18px;
	}
	.entry-content ul.post-meta{
		margin: 15px 0;
	}
	.campaign-detail .campaign-price input{
		width: 100px;
		height: 38px;
	}
	.campaign-detail .process .process-info{
		margin-bottom: 0;
	}
	.calculate-shipping .field .align-left,
	.calculate-shipping .field .align-right{
		float: none;
		padding: 0;
		width: 100%;
	}
	.calculate-shipping .align-left.field-select::after{
		right: 0;
	}
	.calculate-shipping .field .align-left{
		margin-bottom: 20px;
	}
	.coming-soon .site-main .time .time-item p{
		font-size: 40px;
		display: inline-block;
		line-height: 100px;
	}
	.coming-soon .site-main .time .time-item span{
		margin-top: 0;
	}
	ul.menu-category li{
		width: 50%;
	}
	.find-city form button{
		height: 46px;
	}
	.campaigns-action .filter span{
		margin-bottom: 10px;
	}
	.list-faq li a{
		font-size: 14px;
	}
	.campaign-tabs button{
		margin-right: 15px;
		font-size: 13px;
	}
}